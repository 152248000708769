import React from "react"
import LayoutNoStripe from "../../components/layoutNoStripe"
import { Helmet } from "react-helmet"
import {
  Section,
  SectionHeading,
} from "../../styledComponents/section"
import HeroBanner from "../../components/HeroBanner"
import { HeroText } from "../../styledComponents/heroBanner"
import { Video } from 'reactjs-media'

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const PermissioningReset = ({ data, location }) => {
  
    return (
      <>
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />x
      </Helmet>
      <LayoutNoStripe location={location} isHomePage>
        <HeroBanner
            type="hand-to-heart"
          >
            <HeroText>
              <Video 
                  src="https://karinagrant.s3.eu-west-1.amazonaws.com/program/permissioning/hand-to-heart/video.mp4" 
                  poster="/app/images/permission-to-sleep/hand2heart-poster.png"
              />
            </HeroText>
          </HeroBanner>
        <Section>
          <Heading level={1} align="center">
            
          </Heading>
        </Section>
      </LayoutNoStripe>
      </>
    )
}

export default PermissioningReset
